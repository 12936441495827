
.button-primary {
  width: 160px;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-box-shadow: 0px 3px 2px 0px rgba(1,1,1,0.3);
  -moz-box-shadow: 0px 3px 2px 0px rgba(1,1,1,0.3);
  box-shadow: 0px 3px 2px 0px rgba(1,1,1,0.3);
  text-decoration: none;
  border: 1px solid white;
}

button.menu-icon {
  float: right;
  margin-top: 0.3rem;
}

.btn-callback-link {
  border: 1px solid $primary-color;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.8rem;
  padding-right: 1.8rem;
  text-decoration: none;
  background-image: url(/assets/images/iphone.svg);
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: 20px 7px;
  display: inline-block;
  margin-bottom: 1rem;
  border-radius: 5px;
  font-weight: bold;
  background-color: white;
  text-transform: uppercase;
  &:hover {
    color: #fff;
    background-color: $secondary-color;
    background-image: url(/assets/images/iphone-white.svg);
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: 20px 7px;
    transition: background-color 0.25s ease-out, color 0.10s ease-out;
  }
}

.btn-send-text {
  padding-left: 2rem;
}

.btn-callback-text {
  padding-left: 1.5rem
}


.btn-send-link {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 1.3rem;
  padding-right: 1rem;
  text-decoration: none;
  background-image: url(/assets/images/send.svg);
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: 27px 7px;
  display: inline-block;
  margin-bottom: 1rem;
  border-radius: 5px;
  float: right;
}

.btn-send__sidebar__link {
  border: 1px solid $primary-color;
  padding-left: 3.6rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-right: 1.6rem;
  text-decoration: none;
  background-image: url(/assets/images/send-sidebar.svg);
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: 22px 8px;
  background-color: #fff;
  display: inline-block;
  border-radius: 5px;
  &:hover {
    background-color: $secondary-color;
    background-image: url(/assets/images/send.svg);
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: 22px 8px;
    transition: background-color 0.25s ease-out, color 0.10s ease-out;
  }
}

.btn-send__sidebar__text {
  color: $primary-color;
  text-transform: uppercase;
  font-weight: bold;
}

.btn-send__sidebar__link:hover .btn-send__sidebar__text {
  color: #fff;
}
