footer {
  @include grid-column(12);
  background-color: $light-gray;
  padding-top: 1rem;
  margin-top: 2rem;
  .footer-container {
    @include grid-column(12);
    ul {
      margin-left: 0;
      li {
        list-style: none;
        border-bottom: 1px solid $medium-gray;
      }
      .has-submenu {
        border-bottom: none;
      }
     }
     p {
      font-size: 0.8rem;
       color: $medium-gray;
     }
     a {
      color: $medium-gray;
      font-size: 0.8rem;
      text-decoration: none;
       &:hover {
          color: $secondary-color;
        }
        img {
          width: 100px;
          padding-bottom: 1rem;
          display: block;
        }
     }
     h4 {
      text-transform: uppercase;
      color: $medium-gray;
      font-size: 0.8rem;
     }
    @include breakpoint(medium) {
       @include grid-column(3);
    }
    .footer-nested
      li {
        border-bottom: none;
        a {
          padding-bottom: 0.2rem;
          padding-left: 0.5rem;
        }
        &:last-child {
          border-bottom: 0;
          padding-bottom: 0.5rem;
        }
      }

    .footer-icon {
      margin-right: 0.5rem;
      width: 1.8rem;
      display: inline;
    }
  }
}
