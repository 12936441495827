.sidebar{
  @include grid-column(12);
  background-color: $light-gray;
  margin-top: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
    @include breakpoint(medium) {
    @include grid-column(3);
   margin-top: 5rem;
    }
}

.sidebar__link {
  text-decoration: none;
}

.callback {
  .callback__button {
    display: block;
  }
  .callout {
    display: none;
  }
  &.callback-show {
    .callback__button {
      display: none;
    }
    .callout {
      display: block;
    }
  }
}

.sidebar__menu {
  list-style: none;
  margin-left: 0;
}

