header {
  background-color: $light-gray;
  margin-bottom: 1rem;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2);
}

.mobile-nav {
  border-top: 1px solid $light-gray;
}

.navi {
  box-shadow: 0 4px 2px -2px $light-gray;
}

nav a {
  text-decoration: none;
}

.menu-icon {
  top: 0.2rem;
}

.logo {
  max-width: 120px;
  display: inline-block;
   @include breakpoint(medium) {
    margin-left: 0;
    margin-top: 1.3rem;
    max-width: 200px;
   }

}

@include breakpoint(medium up) {

  ul.navi {
    margin-top: 55px;
    margin-bottom: 20px;
    float: right;
      li {
       border-right: 1px solid $primary-color;
           &:last-child {
          border-right: none;
        }
      a {
        font-size: 20px;
        color: $primary-color;
        padding: 0 1rem;
        text-decoration: none;
        &:hover {
          color: $secondary-color;
        }
      }
    }
  }

  ul.navi-dropdown {
    border: none;
    margin-top: 0.8rem;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.35);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.35);
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.35);
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    li {
      border-bottom: 1px solid $medium-gray;
      border-right: none;
        a {
      padding: 0.6rem 1rem;
      text-decoration: none;
      font-size: 16px;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

