figure {
  margin: 0 !important;
}

@include breakpoint(small only) {
  hr {
    margin: 1.5rem auto;
  }
  .office {
    padding-left: 0 !important;
  }
}
section {
  @include grid-row;
}

.content {
  @include grid-column(12);
  @include breakpoint(medium) {
    @include grid-column(9);
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
}
h4 {
  font-weight: bold;
}

h2 a {
  text-decoration: none;
}

.border-head {
  @include breakpoint(medium) {
    height: 5px;
    background-color: $primary-color;
  }
}

// Bilder Positionierung

.bild-block {
  margin-bottom: 1rem;
  display: block;
}

.bild-links {
  float: left;
  padding-right: 1.5rem;
}

.bild-rechts {
  float: right;
  padding-left: 1.5rem;
}

.bild-sw {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 3.5+, IE10 */
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
  -webkit-transition: all .6s ease; /* Fade to color for Chrome and Safari */
}

// Banner Image //

.banner-image {
   position: relative;
   display: block;
   width: 100%; /* for IE 6 */
   height: auto;
   margin-top: -1rem;
   @include breakpoint(medium) {
     height: 200px;
     margin-top: 0;
   }

}

.banner-image__overlay__headline {
  position: relative;
  display: block;
  color: white;
  font-size: 24px;
  background-color: #d36710;
  padding: 0.3rem 1rem 0.3rem 5rem;
  margin-top: 1rem;
  margin-left: -1rem;
  background-image: url(/assets/images/check.svg);
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: 40px 8px;
  text-decoration: none;
  -webkit-box-shadow: 4px 4px 5px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow:    4px 4px 5px 0px rgba(50, 50, 50, 0.75);
  box-shadow:         4px 4px 5px 0px rgba(50, 50, 50, 0.75);
  @include breakpoint(medium) {
    position: absolute;
    padding: 0.3rem 1rem 0.3rem 6rem;
    background-position: 50px 8px;
  }
  &:hover {
    color: white;
  }
}

.banner-image__overlay {
   color: white;
   background: rgb(27, 73, 100); /* fallback color */
   background: rgba(27, 73, 100, 0.9);
   min-height: 200px;
   position: relative;
   @include breakpoint(medium) {
    width: 650px;
    min-height: 200px;
   }
 }

.banner-image__overlay__text {
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  font-size: 20px;
   @include breakpoint(medium) {
     padding-left: 5rem;
     padding-right: 12rem;
     position: absolute;
     padding-top: 0;
     top: 80px;
   }
}

.banner-image__overlay__button {
  position: relative;
  bottom: 0;
  right: 0;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  background-image: url(/assets/images/read.svg);
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: 15px 7px;
    @include breakpoint(medium) {
      margin-right: 2rem;
      margin-bottom: 1.8rem;
      position: absolute;
      margin-left: 0;
  }
}

.banner-image__overlay__button__text {
  padding-left: 2rem;
}

.teasertext {
  @include grid-column(12);
  padding-top: 1rem;
  padding-bottom: 1rem;
    @include breakpoint(medium) {
      padding-top: 2rem;
      padding-bottom: 2rem;
  }
}

.benefits-boxes {
  @include grid-row;
}

.benefits-box {
  @include grid-column(12);
  display: inline;
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
    @include breakpoint(medium) {
    @include grid-column(6);
    margin-bottom: 0;
    display: inline-block;
  }
  @include breakpoint(large) {
    @include grid-column(4);
    margin-bottom: 0;
  }
}

.benefits-box__headline {
  padding-left: 2rem;
  background-size: 25px;
  background-position: 0 0;
  background-repeat: no-repeat;
   @include breakpoint(medium) {
    padding-left: 2.4rem;
    background-size: 35px;
    background-position: 0 2px;
   }
}

.trends-heading {
  @include grid-column(12);
  h3 {
    display: inline-block;
  }
}

.trendbox {
  @include grid-column(12);
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
    @include breakpoint(medium) {
    @include grid-column(3);
    margin-bottom: 0;
  }
}

.trendbox-head {
  background-color: #d36710;
  height: 5px;
}

.trendbox-head-default {
  height: 10px;
}

.memberships {
  @include grid-column(12);
  margin-bottom: 3rem;
  img {
    width: 130px;
    padding-right: 1rem;
    margin-top: 1rem;
   -webkit-filter: grayscale(1); /* Webkit */
    filter: gray; /* IE6-9 */
    filter: grayscale(1); /* W3C */
  }
}

.office {
  @include grid-column(12);
    @include breakpoint(medium) {
    @include grid-column(4);
    padding-left: 0 !important;
  }
}
