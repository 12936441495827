.contactform {
   @include grid-row;
  background-color: $light-gray;
  padding: 1rem 0;
}


.contactform-container {
  @include grid-column(12);
}

.contactform__button__link {
   @include breakpoint(medium) {
    margin-top: 140px;
  }
}


.submit {
  @include breakpoint(medium) {
    float: right;
  }
}

label {
  font-size: 1rem;
}

.error-message {
  color: red;
}

/*/ Input /*/

[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea, select {
  border: none;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0.25,0.25,0.25,0.25);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0.25,0.25,0.25,0.25);
  box-shadow: 0px 0px 5px 0px rgba(0.25,0.25,0.25,0.25);
&:focus {
  border: none;
  }
}

/*/ Label inaktiv /*/

input:disabled {
  box-shadow: none;
}

label.inactive {
  color: $light-gray;
}

/*/ Label error /*/

label.error {
  color: red;
  font-weight: bold;
}
input.input-error {
  border: 2px solid red;
}

/*/ Select /*/

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border:none;
  border-radius: 0;
  font-size: 1em;
  width: 100%
}

select {
  font-weight: bold;
  color: $medium-gray;
  padding-left: 1rem;
  width:100%;
  padding:.5em 1em .5em 1em;
  background-image:url(/assets/images/select-arrow.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: 21px;
}

select:disabled {
  box-shadow: none;
}

select.select-error {
  border: 2px solid red;
}

.fieldset {
  background-color: $light-gray;
}

/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left:0; top: 2px;
  width: 17px; height: 17px;
  border: 1px solid #aaa;
  background: #f8f8f8;
  box-shadow:  0 1px 3px rgba(0,0,0,.3)
}
/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: '✔';
  position: absolute;
  top: 3px; left: 4px;
  font-size: 18px;
  line-height: 0.8;
  color: $primary-color;
  transition: all .2s;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
[type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
[type="checkbox"]:disabled + label {
  color: #aaa;
}
/* accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
  border: 1px dotted blue;
}

/* hover style just for information */
label:hover:before {
  border: 1px solid #4778d9!important;
}

.captchaResult {
  width: 50px;
  display: inline;
}


.captcha-send {
  @include breakpoint(medium) {
  margin-top: 3rem;
  }
}

.uniform__potty {
  position: absolute;
  left: -9999px;
}
