.trends__content {
  @include grid-column(12);
   padding-left: 0 !important;
}

.trends__tags {
  margin-bottom: 2rem;
}

.feed-button__blog {
  display: block;
  text-align: right;
}

.feed-button {
  display: inline-block;
  float: right;
}

.content-blog {
  @include grid-column(12);
  padding-top: 2rem;
  @include breakpoint(medium) {
    @include grid-column(6);
    padding-bottom: 2rem;
  }
}

article {
  border-bottom: 1px solid $medium-gray;
  margin-bottom: 2rem;
}
.content-blog-image {
  float: left;
  max-width: 100%;
  height: auto;
  margin-top: 0.5rem;
  margin-right: 1rem;
}

a.reset-filter {
  text-decoration: none;
  padding: 0.2rem 0.3rem;
  border-radius: 5px;
  margin-top: 0.4rem;
  display: inline-block;
  background-color: $primary-color;
  color: $white;
  &:hover {
    background-color: $secondary-color;
    color: white;
  }
}


a.tag {
  text-decoration: none;
  padding: 0.2rem 0.3rem;
  border: 1px solid $primary-color;
  border-radius: 5px;
  margin-top: 0.4rem;
  display: inline-block;
  &:hover {
    background-color: $secondary-color;
    color: white;
  }
}

span.tag {
  text-decoration: none;
  padding: 0.2rem 0.3rem;
  border: 1px solid $primary-color;
  border-radius: 5px;
  margin-top: 0.4rem;
  display: inline-block;
}
