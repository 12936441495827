
li.accordion-item {
  border-bottom: 4px solid #ffffff;
}

 a.accordion-title {
  text-decoration: none;
  font-size: 1rem;
}

.accordion-content {
  border: none;
}

.accordion-title::before {
    content: '';
    position: absolute;
    border: inset 7px;
    border-color: $primary-color transparent transparent;
    right: 1rem;
    top: 50%;
    margin-top: 0;
}


.is-active > .accordion-title::before {
  content: '';
  border: inset 7px;
  border-color: $primary-color transparent transparent;
  transform: rotate(180deg);
  top: 33%;
}
