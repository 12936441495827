.content-image {
  margin-bottom: 1rem;
}

.branchen-list {
  @include grid-column(12);
  &:last-child:not(:first-child) {
  float: left;
  }
   @include breakpoint(medium) {
    @include grid-column(6);
    margin-left: 0;
  }
}

.memberships-about {
  margin-bottom: 2rem;
  img {
    width: 95px;
    padding-right: 1rem;
    margin-top: 1rem;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
}

